exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-book-post-tsx": () => import("./../../../src/templates/book-post.tsx" /* webpackChunkName: "component---src-templates-book-post-tsx" */),
  "component---src-templates-tag-post-tsx": () => import("./../../../src/templates/tag-post.tsx" /* webpackChunkName: "component---src-templates-tag-post-tsx" */),
  "component---src-templates-week-post-tsx": () => import("./../../../src/templates/week-post.tsx" /* webpackChunkName: "component---src-templates-week-post-tsx" */)
}

